import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1019.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1019.000000) scale(0.100000,-0.100000)">
          
          <path d="M0 5095 l0 -5095 5400 0 5400 0 0 5095 0 5095 -5400 0 -5400 0 0
-5095z m9058 978 c3 -19 -71 -123 -178 -251 -11 -13 -20 -26 -20 -28 0 -3 -32
-45 -71 -93 -45 -56 -81 -92 -97 -96 -29 -7 -1204 -5 -1243 2 -19 4 -33 20
-57 66 -48 94 -116 183 -209 275 -82 83 -91 96 -74 106 43 27 643 41 1741 41
203 0 205 0 208 -22z m-2837 -36 c10 -3 19 -2 21 2 2 4 38 3 81 -3 58 -7 77
-14 78 -25 2 -21 1 -23 -6 -43 -6 -16 -1 -18 38 -18 40 0 45 2 40 19 -3 11
-12 22 -22 24 -9 3 -4 5 11 6 20 1 26 -3 22 -14 -4 -9 0 -15 10 -15 10 0 13 6
10 15 -5 12 4 15 44 15 40 0 52 -4 56 -18 3 -10 21 -22 39 -27 21 -7 33 -17
35 -31 3 -22 -4 -25 -75 -27 -28 -1 -43 -6 -43 -14 0 -10 -22 -13 -80 -13 -73
0 -97 9 -70 25 6 3 10 0 10 -7 0 -8 3 -9 8 -3 8 12 22 16 80 22 23 3 42 10 42
15 0 6 -13 7 -32 3 -67 -14 -169 -17 -183 -5 -20 17 -19 30 3 30 12 0 14 2 4
9 -7 4 -16 21 -21 37 -8 32 5 30 -166 26 -68 -2 -85 -5 -85 -17 0 -10 -11 -15
-35 -15 -28 0 -35 -4 -35 -20 0 -20 -7 -20 -1639 -20 l-1640 0 -3 38 c-2 25
-8 37 -18 37 -13 0 -15 -104 -15 -874 0 -691 3 -875 13 -878 9 -4 13 160 14
794 l3 798 2 -798 3 -797 798 2 c612 2 796 6 795 15 -2 8 11 7 42 -5 25 -9 45
-19 45 -22 0 -3 -391 -5 -870 -5 l-870 0 -2 892 c-3 768 -1 893 11 895 25 5
3534 0 3552 -5z m559 -147 c0 -15 -7 -20 -27 -20 -16 0 -35 5 -43 10 -11 8 -9
9 11 5 17 -4 30 -1 38 9 17 21 21 20 21 -4z m70 -36 c7 -17 15 -24 25 -20 8 3
17 1 20 -4 4 -6 -9 -10 -28 -10 -26 0 -36 6 -45 24 -10 23 -8 28 16 35 1 1 7
-11 12 -25z m-101 -75 c-14 -26 -29 -24 -29 4 0 30 10 40 26 26 10 -8 11 -16
3 -30z m206 13 c6 -4 11 -24 12 -45 1 -31 -1 -38 -15 -35 -9 3 -16 10 -14 18
2 7 -3 17 -10 21 -9 6 -9 9 -1 9 6 0 10 9 8 20 -4 21 3 25 20 12z m58 -62 c5
-18 4 -18 -19 -3 -29 19 -30 23 -5 23 10 0 21 -9 24 -20z m-168 -7 c31 -15 35
-21 35 -55 l0 -38 -80 0 -80 0 0 -40 c0 -24 -5 -40 -12 -40 -9 0 -9 -3 0 -12
11 -11 18 -108 8 -108 -16 0 -36 14 -36 26 0 8 -8 14 -18 14 -17 0 -19 11 -21
121 l-1 122 60 -6 c53 -6 61 -4 70 13 13 24 29 25 75 3z m-3635 -13 c-13 -9
-13 -10 0 -11 8 -1 20 -2 25 -4 6 -1 18 -3 28 -3 34 -4 16 -21 -25 -24 -35 -2
-42 -6 -40 -20 2 -11 -3 -18 -14 -18 -9 0 -23 -8 -30 -17 -7 -10 -15 -15 -19
-11 -4 3 7 19 24 34 33 29 39 49 19 57 -16 7 9 27 32 27 13 0 13 -1 0 -10z
m-105 -10 c3 -5 -1 -10 -10 -10 -9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10
-10z m323 3 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m1052 -3
c0 -5 -18 -10 -40 -10 -22 0 -40 5 -40 10 0 6 18 10 40 10 22 0 40 -4 40 -10z
m146 -5 c-7 -9 -13 -9 -17 -2 -4 5 -14 7 -24 4 -9 -4 -13 -3 -10 2 3 6 18 9
34 9 23 -1 26 -4 17 -13z m-313 -12 c3 -5 -192 -7 -434 -5 -294 2 -439 -1
-439 -8 0 -5 -18 -10 -40 -10 -29 0 -40 4 -40 15 0 13 59 15 474 15 261 0 477
-3 479 -7z m407 -8 c0 -11 -11 -15 -40 -15 -29 0 -40 4 -40 15 0 11 11 15 40
15 29 0 40 -4 40 -15z m730 5 c17 -11 16 -11 -92 -14 -38 -1 -68 2 -68 7 0 4
-5 5 -12 1 -7 -4 -8 -3 -4 4 9 15 154 16 176 2z m-2106 -16 c33 -13 17 -34
-24 -34 -28 0 -40 4 -40 14 0 22 33 32 64 20z m2977 -30 c10 -13 6 -14 -32 -8
-52 8 -53 9 -46 28 5 12 12 13 36 5 16 -6 35 -17 42 -25z m203 20 c9 -3 16
-10 16 -16 0 -5 -5 -6 -12 -2 -7 4 -8 3 -4 -5 4 -6 11 -9 16 -6 5 3 11 1 15
-5 4 -6 -7 -10 -26 -10 -27 0 -30 -2 -19 -15 9 -10 9 -15 1 -15 -6 0 -11 -4
-11 -10 0 -5 8 -10 17 -10 12 0 14 -3 6 -11 -6 -6 -20 -8 -32 -4 -17 5 -19 10
-10 32 8 22 7 24 -4 15 -8 -7 -17 -9 -20 -5 -13 12 -7 23 13 23 23 0 25 10 8
29 -10 11 -10 13 0 7 6 -4 12 -2 12 3 0 12 11 14 34 5z m-1987 -17 c3 -4 -8
-7 -25 -7 -18 0 -32 4 -32 9 0 11 46 8 57 -2z m2558 3 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-2072 -7 c-13 -2 -33 -2 -45
0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m-1903 -49 c0 -38 -15 -45 -24 -11 -10
39 -7 57 9 51 9 -4 15 -19 15 -40z m3929 6 c4 0 12 5 19 12 19 19 23 4 6 -20
-24 -35 -44 -28 -43 16 1 23 3 28 6 15 2 -13 8 -23 12 -23z m-3900 -107 c-1
-84 -2 -94 -12 -72 -14 30 -10 169 4 169 5 0 9 -44 8 -97z m3425 54 c16 -12
16 -14 2 -26 -19 -16 -21 -14 -30 17 -8 25 2 28 28 9z m606 -80 c-1 -29 -2
-30 -14 -14 -8 10 -12 27 -9 37 8 29 23 13 23 -23z m-100 5 c0 -5 -8 -24 -17
-43 l-18 -34 -3 27 c-2 15 3 34 10 42 15 17 28 20 28 8z m-3999 -189 c-13 -9
-19 -8 -33 5 -13 13 -15 28 -11 65 l6 48 27 -53 c25 -48 26 -54 11 -65z m4063
108 c3 -5 -1 -11 -9 -15 -9 -3 -15 0 -15 9 0 16 16 20 24 6z m-3976 -38 c9 -7
26 -13 40 -13 13 0 20 -4 17 -10 -3 -5 1 -10 9 -10 9 0 16 4 16 9 0 5 231 8
555 8 305 -1 555 -5 555 -9 0 -5 -223 -8 -496 -8 -413 0 -498 -2 -510 -14 -14
-15 -54 -11 -54 5 0 5 -10 9 -22 9 -19 0 -20 -2 -8 -11 11 -8 6 -9 -22 -4 -21
3 -39 1 -43 -5 -12 -19 -59 29 -62 63 -1 18 0 25 4 17 3 -8 12 -21 21 -27z
m2268 13 c11 -16 14 -78 14 -331 0 -229 3 -314 12 -323 15 -15 769 -17 833 -2
59 13 138 41 155 55 8 7 26 16 39 20 13 4 55 38 93 76 38 38 75 69 82 69 7 0
29 -10 47 -23 109 -74 222 -119 377 -149 81 -16 80 -29 -16 -148 -45 -54 -111
-120 -121 -120 -4 0 -23 -16 -43 -35 -20 -19 -47 -40 -60 -45 -13 -6 -44 -24
-70 -40 -46 -28 -121 -60 -223 -94 -27 -10 -115 -28 -195 -41 -143 -24 -153
-25 -800 -24 -361 0 -661 3 -667 7 -7 4 -16 19 -20 32 -5 14 -9 269 -9 568 1
532 1 544 21 558 17 12 64 14 278 12 243 -3 260 -4 273 -22z m1825 -18 c5 -19
3 -28 -8 -32 -10 -4 -13 -1 -8 13 3 11 1 22 -5 26 -14 9 -13 28 2 23 7 -3 15
-16 19 -30z m-2661 -2 c0 -14 -65 -34 -91 -29 -42 8 -36 21 14 26 23 2 50 4
60 5 9 1 17 0 17 -2z m2745 -29 c15 -4 -30 -2 -62 3 -2 0 -3 7 -3 16 0 15 2
15 23 1 13 -8 32 -17 42 -20z m-465 -2 c0 -14 -2 -25 -6 -25 -10 0 -94 40 -94
45 0 3 23 5 50 5 47 0 50 -2 50 -25z m339 12 c8 -10 7 -14 -4 -14 -8 0 -15 6
-15 14 0 7 2 13 4 13 2 0 9 -6 15 -13z m-177 -36 c-4 -37 -6 -40 -43 -46 -30
-5 -39 -11 -39 -27 0 -24 -33 -21 -38 5 -2 12 3 17 17 17 18 0 21 6 21 39 0
40 0 40 43 44 23 2 42 4 42 5 0 1 -1 -16 -3 -37z m-258 23 c9 -3 16 -13 16
-22 0 -14 -2 -14 -17 1 -10 8 -29 17 -43 20 l-25 4 27 2 c14 0 33 -2 42 -5z
m1891 -25 c11 -11 -1165 -12 -1256 -1 -39 4 129 9 421 12 544 6 820 2 835 -11z
m-4191 -21 c-4 -27 -3 -30 6 -15 6 9 17 17 24 17 18 0 13 -44 -6 -48 -9 -2
-19 -13 -23 -25 -6 -21 -7 -20 -21 11 -10 24 -20 32 -39 32 -14 0 -25 5 -25
10 0 6 11 10 25 10 14 0 33 9 43 19 9 11 18 20 19 20 1 1 0 -14 -3 -31z m2716
-4 c0 -10 7 -13 28 -8 21 4 24 3 12 -5 -12 -8 -5 -11 28 -11 26 0 41 4 37 10
-3 6 -2 10 4 10 5 0 11 -6 14 -12 3 -10 9 -10 26 0 11 6 38 11 58 10 l38 -1
-45 -7 c-25 -4 191 -7 480 -7 289 0 506 2 483 4 -24 2 -43 8 -43 14 0 9 341
10 368 1 17 -6 15 -27 -4 -43 -13 -10 -17 -10 -24 1 -5 8 -6 18 -4 22 3 4 -37
7 -88 6 l-93 -2 85 -6 85 -6 -82 -2 -83 -2 0 -75 0 -75 -62 -1 -63 -2 75 -7
c95 -10 130 -23 130 -50 0 -17 -7 -20 -40 -20 -22 0 -40 5 -40 10 0 7 -213 10
-647 10 -357 0 -664 0 -683 1 l-35 1 30 8 30 8 -31 1 c-17 0 -34 4 -38 9 -4 4
-17 7 -29 7 -11 0 -22 5 -24 10 -2 7 18 11 52 12 30 1 53 -2 50 -6 -5 -8 44
-19 100 -23 19 -1 9 -4 -25 -9 -37 -5 -18 -6 60 -3 63 2 339 4 613 4 348 0
497 3 497 11 0 6 -15 9 -37 7 -22 -2 -38 2 -41 10 -3 9 -111 12 -483 12 l-479
0 0 75 0 75 -57 0 c-32 0 -68 3 -80 6 -21 6 -23 3 -23 -35 l0 -41 -80 0 -80 0
0 -35 c0 -19 -5 -35 -11 -35 -5 0 -7 5 -4 10 3 6 0 10 -7 10 -7 0 -21 12 -31
25 -18 25 -18 25 8 25 14 0 25 6 25 14 0 10 12 12 50 8 l50 -5 0 32 c0 17 5
31 11 31 6 0 20 11 31 25 20 26 38 25 38 -1z m-2820 -16 c0 -4 -7 -8 -16 -8
-9 0 -12 5 -8 12 5 8 2 9 -9 5 -16 -6 -31 5 -21 16 8 7 54 -14 54 -25z m2498
15 c5 -18 -16 -16 -35 3 -15 14 -14 16 7 12 13 -1 25 -9 28 -15z m1952 -13
c12 -8 11 -10 -5 -9 -11 1 -31 5 -45 9 -24 8 -24 8 5 9 17 0 37 -4 45 -9z
m-1992 -38 c3 -16 -2 -22 -17 -22 -24 0 -35 15 -27 36 9 23 40 13 44 -14z
m2047 -3 c5 -5 1 -9 -12 -9 -29 0 -53 10 -53 22 0 12 50 2 65 -13z m-76 -31
c12 -15 11 -18 -3 -18 -10 0 -16 8 -16 22 0 12 1 19 3 17 1 -2 9 -12 16 -21z
m-4396 -18 c3 -11 1 -23 -4 -26 -5 -3 -9 -16 -9 -28 0 -21 -6 -24 -62 -30 -59
-5 -1031 -9 -1053 -4 -5 1 152 5 350 8 l360 5 3 28 3 27 158 0 c103 0 161 -4
168 -11 7 -7 23 -9 37 -5 19 5 26 13 26 31 0 30 16 33 23 5z m4532 11 c8 -14
-35 -51 -51 -44 -17 6 -18 20 -1 38 14 16 43 19 52 6z m-2065 -27 c0 -14 -3
-15 -17 -3 -10 7 -20 15 -22 16 -2 2 5 3 17 3 14 0 22 -6 22 -16z m1868 4 c-6
-18 -28 -21 -28 -4 0 9 7 16 16 16 9 0 14 -5 12 -12z m-1798 -38 c12 -8 9 -10
-13 -10 -16 0 -26 4 -22 10 8 12 16 12 35 0z m1930 -21 c0 -5 -7 -9 -15 -9
-15 0 -20 12 -9 23 8 8 24 -1 24 -14z m166 -32 c-1 -23 -6 -33 -20 -35 -11 -3
-16 0 -12 7 4 7 -1 11 -13 11 -12 0 -21 7 -21 15 0 8 -5 15 -12 15 -6 0 -4 6
6 13 10 7 30 11 46 9 23 -3 27 -8 26 -35z m-1623 6 c-13 -2 -33 -2 -45 0 -13
2 -3 4 22 4 25 0 35 -2 23 -4z m-3341 -13 c2 -6 58 -11 141 -12 98 0 137 -4
137 -13 0 -8 -117 -12 -469 -14 -454 -2 -611 3 -611 20 0 20 110 27 442 28
232 1 358 -3 360 -9z m5001 -54 c3 -7 6 -82 6 -165 1 -129 -1 -151 -14 -151
-8 0 -15 6 -15 13 0 8 -4 7 -10 -3 -7 -11 -10 -6 -9 20 0 27 2 31 8 15 7 -17
9 -16 12 10 2 17 4 85 4 152 0 114 5 144 18 109z m-433 -21 c0 -14 -5 -25 -11
-25 -7 0 -18 -8 -25 -17 -15 -22 -21 -17 -27 25 -5 29 -3 31 10 21 12 -11 17
-10 24 4 14 25 29 21 29 -8z m-5596 -27 c-5 -8 -1 -8 10 1 15 13 19 13 27 0 6
-10 9 -11 9 -1 0 7 5 10 10 7 19 -12 11 -63 -10 -70 -11 -3 -21 -13 -21 -21
-1 -8 -3 -18 -4 -24 -1 -5 -3 -14 -4 -19 0 -5 -8 -11 -16 -15 -12 -4 -15 3
-15 30 0 29 3 35 16 30 8 -3 12 -2 9 4 -3 6 -2 10 4 10 5 0 9 9 8 20 -1 12 -8
20 -19 20 -11 0 -18 7 -18 20 0 11 5 20 11 20 5 0 7 -6 3 -12z m5993 -20 c-3
-7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-5901 -66 c-12 -12 -18 8
-10 35 l8 28 4 -28 c2 -16 1 -31 -2 -35z m5556 29 c-3 -30 -3 -30 -31 -33 -17
-2 -26 5 -36 30 l-15 33 43 -3 c38 -3 42 -5 39 -27z m318 -46 c0 -68 -2 -75
-20 -75 -18 0 -20 7 -20 69 0 65 5 77 33 80 4 1 7 -33 7 -74z m-337 -77 c39
-1 38 -2 -13 -7 -47 -5 -55 -9 -58 -28 -2 -13 -8 -23 -13 -23 -14 0 0 43 17
53 8 4 15 20 15 35 l2 27 4 -27 c4 -25 9 -28 46 -30z m-5537 -25 c-6 -21 -9
-24 -17 -12 -7 12 -9 11 -9 -3 0 -10 -6 -18 -14 -18 -8 0 -21 -3 -30 -6 -10
-4 -16 -1 -16 10 0 17 38 42 58 38 7 -2 15 4 18 13 10 25 17 9 10 -22z m5832
-18 c-3 -42 -28 -48 -28 -7 0 16 3 32 7 35 15 16 23 5 21 -28z m-334 -37 c-4
-39 -11 -55 -21 -55 -12 0 -14 9 -9 36 3 20 10 37 16 39 5 2 10 13 10 25 0 11
2 18 5 15 3 -3 3 -30 -1 -60z m-134 -18 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16
10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m452 -20 c-4 -28 -1 -30 40 -41 39 -11
45 -16 46 -39 1 -26 0 -26 -34 -16 -22 7 -34 17 -34 28 0 15 -9 18 -50 18
l-50 0 0 40 0 40 43 0 c43 0 43 -1 39 -30z m-5788 -6 c24 -24 19 -33 -13 -24
-16 4 -32 6 -35 2 -3 -3 -6 1 -6 10 0 31 29 38 54 12z m4319 5 c15 -9 146 -11
464 -10 305 2 443 -1 443 -8 0 -6 -18 -14 -40 -17 -22 -4 -37 -11 -34 -16 4
-7 -93 -8 -277 -3 -156 4 -351 8 -432 9 -151 2 -173 7 -160 43 6 16 9 16 36 2z
m1030 -9 c-6 -22 -33 -24 -40 -3 -4 10 9 17 39 22 3 0 4 -8 1 -19z m567 16 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-5833
-18 c23 13 54 -15 32 -29 -18 -11 -72 -12 -65 0 3 5 -1 11 -9 15 -8 3 -15 10
-15 16 0 8 6 8 20 0 13 -7 26 -7 37 -2z m1151 -2 c31 -19 -7 -26 -114 -23 -74
3 -122 0 -137 -8 -15 -8 -138 -12 -404 -14 -378 -3 -382 -2 -393 18 -8 16 -8
22 2 26 7 3 209 6 448 8 546 3 583 3 598 -7z m236 -12 c8 -21 -7 -62 -25 -67
-10 -3 -19 -11 -19 -19 0 -19 -46 -38 -59 -25 -14 14 -14 27 2 60 13 29 27 33
57 17 12 -7 21 -6 28 3 11 13 14 12 -103 21 -33 3 -61 5 -63 6 -2 0 0 5 3 10
9 14 173 9 179 -6z m46 6 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m3960 -19 c0 -5 -9 -7 -20 -4 -11 3 -20 7 -20 9 0 2 9 4
20 4 11 0 20 -4 20 -9z m-4250 -51 l0 -40 -40 0 -40 0 0 40 0 40 40 0 40 0 0
-40z m76 -5 c-9 -47 -36 -48 -36 -1 0 25 5 36 18 38 24 6 25 3 18 -37z m2938
-5 c9 -18 10 -30 4 -30 -16 0 -37 32 -31 47 7 18 10 17 27 -17z m1504 -5 c11
-14 27 -25 36 -25 11 0 16 -7 14 -17 -2 -13 3 -17 15 -15 11 2 0 -16 -33 -49
-38 -38 -54 -49 -68 -44 -16 8 -16 8 1 12 9 3 17 9 17 14 0 6 -4 7 -10 4 -5
-3 -10 -1 -10 4 0 6 8 11 18 11 9 0 27 9 40 18 l24 18 -41 19 c-34 15 -41 23
-41 47 0 35 12 36 38 3z m57 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10
4 10 3 0 8 -4 11 -10z m-1611 -36 c-5 -20 -2 -26 15 -31 25 -6 28 -25 4 -22
-10 1 -21 0 -25 -1 -10 -3 -26 -7 -35 -9 -5 -1 -1 -5 7 -11 22 -14 7 -20 -26
-11 -16 5 -23 10 -16 10 6 1 12 8 12 16 0 8 9 19 20 25 11 6 20 15 20 21 0 9
21 38 28 39 1 0 -1 -12 -4 -26z m137 -24 c10 -6 19 -20 19 -30 0 -17 -7 -20
-40 -20 l-40 0 0 -40 c0 -40 0 -40 -39 -40 -47 0 -51 5 -39 48 8 28 13 32 43
32 32 0 35 2 35 30 0 24 4 30 21 30 11 0 29 -5 40 -10z m1330 -33 c6 -21 3
-29 -12 -37 -10 -6 -19 -7 -19 -2 0 5 -12 7 -27 4 -26 -5 -27 -4 -11 11 9 10
21 17 27 17 6 0 11 9 11 21 0 29 22 19 31 -14z m-4311 -38 c0 -11 -4 -17 -10
-14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m3080 -19 c0 -22 -4
-40 -10 -40 -5 0 -10 18 -10 40 0 22 5 40 10 40 6 0 10 -18 10 -40z m-330 -4
c0 -7 -9 -16 -20 -19 -25 -6 -26 1 -2 19 22 17 22 17 22 0z m-2930 -12 c0 -8
-4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10 9 6 0 10 -7 10 -16z m4348 -1
c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m127 -24 c-3 -4 -12
-6 -20 -3 -8 4 -13 10 -10 15 3 4 12 6 20 3 8 -4 13 -10 10 -15z m-140 -10
c-18 -5 -33 -5 -39 1 -7 7 4 10 30 9 39 -1 39 -1 9 -10z m-4233 -31 c-7 -7
-12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5 12 2 2 -3 -1 -11 -7 -17z m4317 -4 c2 -2
-12 -4 -33 -4 -22 0 -35 4 -31 10 5 9 52 4 64 -6z m-1559 -9 c0 -8 -6 -15 -12
-15 -9 0 -8 -3 2 -10 22 -14 6 -22 -21 -11 -13 5 -19 10 -12 10 6 1 15 10 18
21 7 23 25 27 25 5z m1445 -12 c-11 -11 -43 -19 -93 -23 -103 -10 -1177 -11
-1226 -2 -38 7 -63 32 -32 32 9 0 16 -5 16 -11 0 -17 1312 -8 1318 9 2 6 10
12 19 12 12 0 12 -3 -2 -17z m-5575 -304 c0 -34 -17 -39 -22 -6 -2 18 -10 23
-45 25 l-43 3 0 -49 c0 -46 2 -49 24 -49 14 0 27 6 30 13 11 28 25 14 19 -18
-8 -42 -12 -47 -24 -25 -6 9 -19 17 -29 17 -17 0 -20 -7 -20 -50 l0 -50 43 0
c38 0 46 4 57 28 l14 27 4 -28 c2 -16 2 -30 0 -32 -3 -4 -152 -8 -183 -5 -19
1 -18 2 3 10 21 9 22 15 22 104 0 86 -2 95 -20 101 -41 13 -9 20 79 17 l91 -3
0 -30z m2200 -63 c0 -80 4 -108 15 -120 8 -8 15 -17 15 -20 0 -10 -36 -6 -47
5 -8 8 -17 8 -31 0 -50 -27 -92 6 -92 70 0 52 46 104 85 96 21 -3 25 0 25 19
0 12 -5 24 -12 26 -17 6 0 28 23 28 18 0 19 -8 19 -104z m3200 -21 l0 -104 28
-4 27 -4 -28 -2 c-15 0 -30 5 -32 12 -8 23 -10 215 -2 210 4 -2 7 -51 7 -108z
m-2174 56 c-4 -5 0 -11 6 -14 7 -2 11 -7 8 -10 -9 -10 -33 10 -26 22 4 6 9 11
12 11 3 0 3 -4 0 -9z m-2712 -23 c10 -13 16 -44 16 -75 0 -39 4 -54 18 -62 16
-9 16 -10 0 -16 -9 -4 -26 -2 -37 4 -15 9 -26 8 -41 -1 -26 -17 -55 0 -55 33
0 18 10 29 40 44 22 11 42 21 44 22 2 2 -1 14 -7 28 -14 30 -38 33 -47 5 -8
-26 -25 -26 -25 0 0 41 69 54 94 18z m536 7 c7 -8 9 -23 5 -32 -6 -16 -8 -16
-22 5 -19 27 -43 29 -43 2 0 -12 14 -29 35 -42 43 -29 50 -44 30 -74 -18 -28
-75 -33 -95 -9 -21 25 4 44 28 22 10 -9 26 -17 35 -17 29 0 19 26 -20 51 -23
14 -39 34 -41 47 -7 48 59 82 88 47z m493 -6 c-8 -8 -7 -23 4 -55 8 -24 18
-44 22 -44 4 0 14 20 23 45 10 25 13 45 8 45 -6 0 -10 5 -10 10 0 6 14 10 30
10 17 0 30 -4 30 -10 0 -5 -4 -10 -8 -10 -5 0 -26 -43 -47 -96 -39 -96 -69
-132 -100 -120 -24 9 -18 36 9 36 47 0 51 23 21 105 -15 41 -31 75 -36 75 -5
0 -9 5 -9 10 0 6 17 10 37 10 27 0 34 -3 26 -11z m1217 -74 c0 -26 6 -50 16
-59 14 -15 14 -17 0 -22 -9 -3 -23 -1 -32 6 -11 10 -18 10 -26 2 -16 -16 -53
-15 -67 2 -15 18 -1 50 29 66 27 15 34 12 16 -6 -28 -28 -16 -61 18 -48 21 8
30 42 16 59 -9 11 -9 15 -1 15 12 0 15 41 4 56 -5 5 0 1 10 -9 11 -12 17 -35
17 -62z m1038 73 c7 -7 12 -21 11 -32 0 -19 -1 -18 -10 4 -6 13 -15 28 -22 32
-7 4 -8 8 -1 8 5 0 15 -5 22 -12z m540 -19 c-3 -8 -7 -3 -11 10 -4 17 -3 21 5
13 5 -5 8 -16 6 -23z m482 -45 c0 -58 2 -66 23 -74 l22 -9 -23 0 c-27 -1 -29
5 -36 92 -4 43 -2 66 4 62 6 -3 10 -35 10 -71z m675 63 c0 -1 6 -13 13 -25 8
-14 11 -36 7 -53 -9 -41 -25 -46 -18 -6 6 40 -9 74 -40 88 -18 8 -17 8 8 3 16
-3 30 -6 30 -7z m-2819 -22 c-3 -9 -11 -13 -16 -10 -8 5 -7 11 1 21 14 18 24
11 15 -11z m2237 12 c3 -7 3 -37 1 -65 -3 -40 0 -54 14 -62 15 -10 15 -10 -3
-8 -17 3 -20 12 -23 68 -2 36 -8 68 -15 72 -8 5 -7 8 4 8 9 0 19 -6 22 -13z
m-1681 -42 c-6 -49 5 -85 26 -86 11 0 12 -2 4 -6 -26 -10 -42 18 -42 73 0 30
4 54 8 54 4 0 6 -16 4 -35z m1078 -13 c32 -18 40 -28 39 -50 l-1 -27 -9 25
c-5 14 -25 35 -44 48 -20 12 -34 30 -34 40 1 13 2 13 5 2 3 -8 22 -25 44 -38z
m-483 -69 c15 -12 15 -13 1 -13 -20 0 -29 22 -26 63 l1 32 4 -35 c2 -19 11
-41 20 -47z m-79 0 c14 -12 13 -13 -5 -13 -26 0 -37 15 -28 38 6 14 8 15 12 2
3 -8 12 -21 21 -27z m537 -12 c-6 -5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15
11 -17z"/>
<path d="M5176 3914 c-38 -38 -4 -129 43 -117 18 5 21 13 21 59 0 42 -4 55
-19 64 -25 13 -27 13 -45 -6z"/>
<path d="M3525 3836 c-16 -12 -17 -16 -6 -30 17 -20 51 -4 51 24 0 23 -18 26
-45 6z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
